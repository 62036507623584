<template>
  <div
    data-w-id="a6c4c924-bd52-e506-6db3-1b47727f27b3"
    class="text-decoration-none color-transition-none flex-vertical w-inline-block"
  >
    <div class="card-link---image-wrapper bordert26">
      <nuxt-link :external="true" :to="`/blogs/${blog.url}`" class="tw-w-full">
        <library-image
          :src="blog.largeImage"
          loading="eager"
          :alt="blog.title"
          class="card-link---image news-slider-card minh180"
        />
      </nuxt-link>
    </div>
    <div class="card-5 blog-link-text-card newscard2">
      <div class="mg-bottom-16px">
        <h3
          class="card-link-title mg-bottom-4px"
          ::style="`color: var(--${variables?.titleColor || 'text_primary'}) !important;`"
        >
          <strong ::style="`color: var(--${variables?.titleColor || 'text_primary'}) !important;`">{{
            blog.title
          }}</strong>
        </h3>
        <div
          class="color-neutral-600 text-401 lineheight16 tw-line-clamp-6"
          :style="`color: var(--${variables?.textColor || 'text_primary'}) !important;`"
        >
          {{ blog.description }}
        </div>
      </div>
      <nuxt-link
        :to="`/blogs/${blog.url}`"
        :external="true"
        data-w-id="12465e72-f1f7-0ed0-b410-3d70d85faa4b"
        class="text-200 bold color-neutral-800"
      >
        <div class="link-text" :style="`color: var(--${variables?.textColor || 'text_primary'}) !important;`">
          Read more
        </div>
        <div class="line-rounded-icon link-icon-right" style="color: black"></div>
      </nuxt-link>
    </div>
  </div>
</template>

<script lang="ts">
import type { PropType } from 'vue';
import type { BlogItemComponent } from '~/units/blogs/types';

export default defineNuxtComponent({
  name: 'Theme0BlogCard',

  props: {
    blog: {
      required: true,
      type: Object as PropType<BlogItemComponent>,
    },

    index: {
      required: true,
      type: Number,
    },

    variables: {
      required: false,
      type: Object,
    },
  },
});
</script>
